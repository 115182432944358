
const screens = {
    'login': 'auth/login',
    'authTeams': 'auth/teams',
    'cms': 'organizer?tab=dashboard',
    'cmsCompetitions': 'organizer?tab=challenges',
    'cmsEntity': 'organizer?tab=entitySettings',
    'cmsAdmin': 'organizer?tab=admin',
    'organizer': 'organizer',
    'betCreate': 'bets/new',
    'categoryDetail': 'categories/:id',
    'clubhouse': 'clubhouse',
    'creator': 'creators/:id',
    'creators': 'creators',
    tabs: 'tabs',
    home: {
        screens: {
            matches: 'matches',
            competitions: 'competitions',
            'user-detail': 'auth/user-settings',
            creators: 'creators',
        },
    },
    'profile': 'users/me',
    'settings': 'settings',
    'userDetail': 'users/:id',
    'currentUserDetail': 'users/me',
    'competitions': 'competitions',
    'competition': 'competitions/:id',
    'competitionRules': 'competitions/:id/rules',
    'community': 'community',
    'challenges': 'challenges',
    'challenge': 'challenges/:id',
    'challengeChapter': 'challenges/:id/chapter/:groupId',
    'challengeChapterVideo': 'challenges/:id/chapter/:groupId/video',
    'challengeRules': 'challenges/:id/rules',
    'challengeLeaderboard': 'challenges/:id/leaderboard',
    'checkout': 'checkout/:id',
    'checkoutSuccess': 'checkout/:id/success',
    'checkoutCancel': 'checkout/:id/cancel',
    'clubs': 'clubs',
    'fixture': 'matches/:id',
    'fixtureResult': 'matches/:id/result',
    'fixtureCreate': 'matches/new',
    'fixtureScorecard': 'matches/:id/scorecard',
    'fixtureScorecardParticipants': 'matches/:id/scorecard/participants',
    'fixtureEdit': 'matches/:id/edit',
    'invitationsDetail': 'invitations/:id',
    'notifications': 'notifications',
    'notificationsConfig': 'notifications/config',
    'notFound': 'not-found',
    'postDetail': 'posts/:id',
    'shop': 'shop',
    'shopItemDetail': 'shop/:id',
    'teamSelect': 'teams/select',
    'teamDetail': 'teams/:id',
    'templateDetail': 'templates/:id',
    'templatesHowItWorks': 'templates/howItWorks',
    'train': 'train',
    'transaction': 'wallet/transaction',
    'transactions': 'wallet/transactions',
    'update': 'update',
    'competitionCms': 'organizer/competitions/:id',
    'templateCms': 'organizer/templates/:id',
    'categoryCms': 'organizer/categories/:id',
    'authCompetitions': 'organizer?tab=challenges',
    'wallet': 'wallet',
    'walletHowItWorks': 'wallet/how-it-works',
    'walletEarn': 'wallet/earn',
};

// Bad replace but works for now
const getScreen = (screen: keyof typeof screens) => `/${screens[screen]}`.replace(':id', '[id]').replace(':groupId', '[groupId]');

export default screens;

export {getScreen};
